import React from 'react'
import HoverHorizontalTable from '../../../reusable/HoverHorizontalTable/HoverHorizontalTable'
import * as style from '../../../reusable/HoverHorizontalTable/hover-horizontal-hable.module.scss'
import Section from '../../../common/SectionComponent/Section'

const CaseStudyRoadmap = ({ data }) => {
  const tableColors = {
    start: 0.02,
    end: 0.16,
  }
  return (
    <>
      <Section newClass={style.section} title={data.title}>
        <p className="text-color__gray--on-dark">{data.description}</p>
      </Section>
      <HoverHorizontalTable
        newClass={style.caseStudy}
        tabsColors={tableColors}
        platesData={data.milestones}
      />
    </>
  )
}

export default CaseStudyRoadmap
