import React, { useEffect, useMemo, useRef, useState } from 'react'
import className from 'classnames'
import Fade from 'react-reveal/Fade'
import * as style from './stagesL-line.module.scss'
import * as s from '../../../common/SectionComponent/section-component.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'
import useWindowSize from '../../../../hooks/useWindowSize'

const StagesLine = ({ data }) => {
  const [active, setActive] = useState(null)
  const [linePosition, setLinePosition] = useState(0)
  const [activeLineWidth, setActiveLineWidth] = useState(0)
  const [isMobile, setIsMobile] = useState(false)
  const itemsRef = useRef([])
  const size = useWindowSize()
  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, data.stages.length)
  }, [data.stages])

  const activeBLockToggle = (e, index) => {
    const LineSize = isMobile
      ? itemsRef.current[index].offsetTop
      : itemsRef.current[index].getBoundingClientRect().x
    setActive(index)
    setActiveLineWidth(LineSize)
  }
  const changeLines = () => {
    setLinePosition(itemsRef.current[0].offsetHeight / 2)
  }
  useEffect(() => {
    setIsMobile(size.width && size.width < 768)
    changeLines()
    setActive(0)
  }, [size])

  useEffect(() => {
    changeLines()
  }, [])
  return (
    <section className={className(s.section, s.light)}>
      <div className="container">
        <Fade bottom>
          <h2 className={s.title}>{data.title}</h2>
        </Fade>
        <p className="text-color__gray--on-light">{data.description}</p>
      </div>

      <div className={style.caseStageWrap}>
        <div
          className={style.linesBlock}
          style={
            isMobile
              ? { left: `${linePosition + 8}px` }
              : { top: `${linePosition}px` }
          }
        >
          <div
            style={
              isMobile
                ? { maxHeight: `${activeLineWidth}px` }
                : { maxWidth: `${activeLineWidth}px` }
            }
            className={className(
              style.caseStageLine,
              style.caseStageLineActive
            )}
          />
          <div className={style.caseStageLine} />
          <div className={style.caseStageLineMobile} />
        </div>
        <div className="container">
          <div className={style.caseStageItems}>
            {data.stages.map((elem, index) => (
              <div
                ref={(el) => (itemsRef.current[index] = el)}
                id={index}
                key={keyGenerator(index, elem.name)}
                className={className(
                  style.caseStageItem,
                  active > index && style.caseStageItemActive
                )}
                onMouseEnter={(e) => activeBLockToggle(e, index)}
                onClick={(e) => activeBLockToggle(e, index)}
              >
                <span
                  className={className(
                    style.caseStageItemCircle,
                    active === index && style.caseStageActiveCircle,
                    'circle'
                  )}
                />
                <p className={style.caseStageItemTitle}>{elem.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default StagesLine
