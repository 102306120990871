import React from 'react'
import PropTypes from 'prop-types'
import MainLayout from '../layouts/MainLayout'
import PageMainSection from '../components/reusable/PageMainSection/PageMainSection'
import CaseStudyClient from '../components/Pages/case-study/CaseStudyClient/CaseStudyClient'
import CaseStudyWpContent from '../components/Pages/case-study/CaseStudyWpContent/CaseStudyWpContent'
import StagesLine from '../components/Pages/case-study/StagesLine/StagesLine'
import CaseStudyQuote from '../components/Pages/case-study/CaseStudyQuote/CaseStudyQuote'
import CaseStudyRoadmap from '../components/Pages/case-study/CaseStudyRoadmap/CaseStudyRoadmap'
import {
  CASE_STUDY_CLIENT,
  CASE_STUDY_QUOTE,
  CASE_STUDY_STAGES,
  CASE_STUDY_ROADMAP,
  CASE_STUDY_WYSIWYG,
  CASE_STUDY_FACTS,
} from '../types/types'
import CaseStudyFacts from '../components/Pages/case-study/CaseStudyFacts/CaseStudyFacts'
import PageNavigator from '../components/common/PageNavigator/PageNavigator'

const PortfolioItem = ({
  pageContext: {
    slug: {
      metaTags,
      portfolioItem: {
        caseStudySections,
        image: { sourceUrl },
        header: { title, description },
      },
    },
    navigationData,
  },
  location,
}) => {
  const navigation = navigationData.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  )

  const filterSection = (type) => {
    const result =
      caseStudySections &&
      caseStudySections.filter((elem) => elem.fieldGroupName === type)
    return type !== CASE_STUDY_WYSIWYG ? result[0] : result
  }

  const wpData = filterSection(CASE_STUDY_WYSIWYG)

  return (
    <MainLayout seoData={metaTags} location={location}>
      <div id="top" />
      <PageMainSection
        title={title}
        description={description}
        background={sourceUrl}
      />
      {caseStudySections && (
        <>
          {filterSection(CASE_STUDY_CLIENT) && (
            <CaseStudyClient data={filterSection(CASE_STUDY_CLIENT)} />
          )}

          <CaseStudyWpContent data={wpData[0]} scrollToId="brief" />

          {filterSection(CASE_STUDY_STAGES) && (
            <StagesLine data={filterSection(CASE_STUDY_STAGES)} />
          )}

          <CaseStudyWpContent data={wpData[1]} scrollToId="challanges" />

          <CaseStudyWpContent isLight data={wpData[2]} scrollToId="solutions" />

          {filterSection(CASE_STUDY_QUOTE) && (
            <CaseStudyQuote data={filterSection(CASE_STUDY_QUOTE)} />
          )}
          {filterSection(CASE_STUDY_ROADMAP) && (
            <CaseStudyRoadmap data={filterSection(CASE_STUDY_ROADMAP)} />
          )}
          <CaseStudyWpContent isLight data={wpData[3]} scrollToId="result" />
          {filterSection(CASE_STUDY_FACTS) && (
            <CaseStudyFacts data={filterSection(CASE_STUDY_FACTS)} />
          )}
          <PageNavigator navigationData={navigation} location={location} />
        </>
      )}
    </MainLayout>
  )
}

PortfolioItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pageContext: PropTypes.object.isRequired,
}
export default PortfolioItem
