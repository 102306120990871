import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import className from 'classnames'
import { Link } from 'gatsby'
import * as style from './case-study-client.module.scss'
import keyGenerator from '../../../../utils/keyGenerator'
import Modal from '../../../common/Modal/Modal'
import ModalVideo from '../../../common/Modal/ModalVideo/ModalVideo'

const PortfolioItem = ({ data }) => {
  if (!data) {
    return null
  }
  const modalRef = useRef()
  const openVideo = () => {
    modalRef.current.openModal()
  }
  return (
    <section className={className('section', style.caseInfo)}>
      <div className={className('container', style.wrap)}>
        <div className={style.stats}>
          <p className={style.statsDescription}>{data.description}</p>
          <div className={style.statsInfo}>
            <p>
              Industry:
              <b>{data.industry}</b>
            </p>
            <p>
              Location:
              <b>{data.location}</b>
            </p>
            <p>
              Partnership since:
              <b> {data.startDate} </b>
            </p>
          </div>
          <div className={style.statsTags}>
            {data.serviceDirections.map(
              (elem, index) =>
                elem.linkCopy.url && (
                  <Link
                    to={elem.linkCopy.url.uri}
                    key={keyGenerator(index, elem.name)}
                    className={className(
                      style.statsTag,
                      elem.selected && style.statsTagActive
                    )}
                  >
                    {elem.name}
                  </Link>
                )
            )}
          </div>
        </div>
        <div className={style.aside}>
          <div className={style.asideText}>
            {data.achievements.map((elem, index) => (
              <p key={keyGenerator(index, elem.name)}>{elem.name}</p>
            ))}
          </div>

          <div
            role="button"
            tabIndex={0}
            className={style.button}
            onKeyDown={openVideo}
            onClick={openVideo}
            style={{
              backgroundImage: `url('${
                data.playbackButton.previewImage &&
                data.playbackButton.previewImage.sourceUrl
              }')`,
            }}
          >
            <div className={style.content}>
              <span />
              <b>{data.playbackButton.text}</b>
            </div>
          </div>
        </div>
      </div>
      {data.playbackButton.embeddedHtml && (
        <Modal ref={modalRef}>
          <ModalVideo videoData={data.playbackButton.embeddedHtml} />
        </Modal>
      )}
    </section>
  )
}
PortfolioItem.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string,
    industry: PropTypes.string,
    location: PropTypes.string,
    startDate: PropTypes.string,
    serviceDirections: PropTypes.arrayOf(PropTypes.object),
    achievements: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
}
export default PortfolioItem
