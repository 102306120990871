// extracted by mini-css-extract-plugin
export var animateCircle = "page-navigator-module--animate-circle---n7qI";
export var bounce = "page-navigator-module--bounce--zGLdx";
export var ldsRing = "page-navigator-module--lds-ring--98bId";
export var linkHeading = "page-navigator-module--linkHeading--Skq+0";
export var linkText = "page-navigator-module--linkText--sRA49";
export var linksWrap = "page-navigator-module--linksWrap--CguVi";
export var plate = "page-navigator-module--plate---uLV4";
export var plateNext = "page-navigator-module--plateNext--KeEP+";
export var platePrev = "page-navigator-module--platePrev--u1TJt";
export var section = "page-navigator-module--section--V7tPD";