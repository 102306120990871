import React, { useEffect, useState } from 'react'
import className from 'classnames'
import Section from '../../../common/SectionComponent/Section'
import * as style from './case-study-wp-content.module.scss'
import ButtonPrimary from '../../../common/buttons/ButtonPrimary'
import useWpContentOverflow from '../../../../hooks/useWpContentOverflow'
import HiddenScrollItem from '../../../common/HiddenScrollItem/HiddenScrollItem'
import {
  parseContentLinkAndImage,
} from '../../../../utils/functions'

const CaseStudyWpContent = ({ data, scrollToId, isLight }) => {
  if (!data) {
    return null
  }
  const [buttonState, setButtonState] = useState(true)
  const { toggle, isOverflow } = useWpContentOverflow(scrollToId)

  const isMoreContent = data.body.split('\n<p><!--more--></p>\n')

  useEffect(() => {
    setButtonState(isMoreContent.length > 1)
  }, [])

  return (
    <Section isLight={isLight} title={data.title}>
      <HiddenScrollItem id={scrollToId} />
      <p
        className={className(
          isLight ? 'text-color__gray--on-light' : 'text-color__gray--on-dark'
        )}
      >
        {data.description}
      </p>
      <div
        className={className(
          style.parseWrap,
          isLight && style.light,
          'wp_content',
          isOverflow && 'wp_content_visible'
        )}
      >
        <div className={className(style.parseBody, 'parseBody')}>
          {parseContentLinkAndImage(isMoreContent[0])}
        </div>
        {isMoreContent[1] && (
          <div
            hidden={!isOverflow}
            className={className(style.parseBody, 'parseBody')}
          >
            {parseContentLinkAndImage(isMoreContent[1])}
          </div>
        )}
        {buttonState && (
          <div
            role="button"
            tabIndex={0}
            onKeyDown={toggle}
            className={style.btn}
            onClick={toggle}
          >
            <ButtonPrimary
              isLight={isLight}
              arrowDirection={!isOverflow ? 'down' : 'up'}
            >
              {!isOverflow ? 'Show More' : 'Show less'}
            </ButtonPrimary>
          </div>
        )}
      </div>
    </Section>
  )
}
export default CaseStudyWpContent
