// extracted by mini-css-extract-plugin
export var animateCircle = "case-study-client-module--animate-circle--qtk5n";
export var aside = "case-study-client-module--aside--G3Tu5";
export var asideText = "case-study-client-module--asideText--9aDIS";
export var bg = "case-study-client-module--bg--Tockd";
export var bounce = "case-study-client-module--bounce--RPak4";
export var button = "case-study-client-module--button--u1HCC";
export var caseInfo = "case-study-client-module--caseInfo--cIshv";
export var content = "case-study-client-module--content--7fyvC";
export var ldsRing = "case-study-client-module--lds-ring--drJdh";
export var stats = "case-study-client-module--stats--nBMaZ";
export var statsDescription = "case-study-client-module--statsDescription--xE39e";
export var statsInfo = "case-study-client-module--statsInfo--OMNPK";
export var statsTag = "case-study-client-module--statsTag--AtBZe";
export var statsTagActive = "case-study-client-module--statsTagActive--vpbAK";
export var statsTags = "case-study-client-module--statsTags--q3Pdk";
export var wrap = "case-study-client-module--wrap--eP2WH";