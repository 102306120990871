import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import * as style from './case-study-quote.module.scss'
import Section from '../../../common/SectionComponent/Section'

const CaseStudyQuote = ({ data: { photo, name, position, quote } }) => {
  const image = getImage(photo.localFile.childImageSharp)

  return (
    <Section hideText newClass={style.section}>
      <div className={style.wrap}>
        <div className={style.image}>
          <GatsbyImage width={200} height={200} alt={name} image={image} />
        </div>
        <div>
          <blockquote className={style.quote}>{quote}</blockquote>
          <figcaption className={style.cite}>
            <span>{name}</span>
            <span>{position}</span>
          </figcaption>
        </div>
      </div>
    </Section>
  )
}

export default CaseStudyQuote
