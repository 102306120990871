// extracted by mini-css-extract-plugin
export var animateCircle = "stagesL-line-module--animate-circle--tK1Dt";
export var bounce = "stagesL-line-module--bounce--FM5s4";
export var caseStageActiveCircle = "stagesL-line-module--caseStageActiveCircle--89PSm";
export var caseStageItem = "stagesL-line-module--caseStageItem--iymJD";
export var caseStageItemActive = "stagesL-line-module--caseStageItemActive--FJNW8";
export var caseStageItemCircle = "stagesL-line-module--caseStageItemCircle--76apo";
export var caseStageItemTitle = "stagesL-line-module--caseStageItemTitle--Q70hC";
export var caseStageItemTitleOverflow = "stagesL-line-module--caseStageItemTitleOverflow--X1Zhl";
export var caseStageItems = "stagesL-line-module--caseStageItems--ck6ow";
export var caseStageLine = "stagesL-line-module--caseStageLine--AHZzP";
export var caseStageLineActive = "stagesL-line-module--caseStageLineActive--ObFWq";
export var caseStageLineMobile = "stagesL-line-module--caseStageLineMobile--fB1qS";
export var caseStageWrap = "stagesL-line-module--caseStageWrap--h8zZH";
export var ldsRing = "stagesL-line-module--lds-ring--tl7Zl";
export var linesBlock = "stagesL-line-module--linesBlock--9xNp2";
export var sectionTitle = "stagesL-line-module--section-title--Ac6Jk";