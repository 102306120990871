import React from 'react'
import * as style from './case-study-facts.module.scss'
import ButtonPrimary from '../../../common/buttons/ButtonPrimary'
import Section from '../../../common/SectionComponent/Section'
import keyGenerator from '../../../../utils/keyGenerator'

const CaseStudyFacts = ({ data }) => (
  <Section title={data.title}>
    <dl className={style.numbers}>
      {data.numbers.map((elem, index) => (
        <div key={keyGenerator(index, elem.number)} className={style.number}>
          <dt>{elem.number}</dt>
          <dd>{elem.description}</dd>
        </div>
      ))}
    </dl>
    {data.links && (
      <div className={style.buttons}>
        {data.links.map((elem) => (
          <ButtonPrimary buttonData={elem.factsButton} />
        ))}
      </div>
    )}
  </Section>
)

export default CaseStudyFacts
